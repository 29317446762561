/* web line clamp can be looked at for further details on this process */
.projectInfo {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;  
    overflow: hidden;
  &:hover {
    -webkit-line-clamp: 8;
    transition: 0.8s;
  }
}

.tileHoverEffect:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  transform: translateY(-3px);
  transition: box-shadow 0.3s ease-in-out 0.2s, transform 0.3s ease-in-out 0.2s; /* Added 0.2s delay */
}

.tileHoverEffect {
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;
}


#explore-offers {
  display: flex;
  padding: 15px;
}

 .projectInfo {
  order: 1;
} .issuer-offer {
  order:2;
} .offer-divier {
  order:3;
  height: 3px;
  margin: 30px 0px 15px 0px;
} .phase-offer{
  order: 4;
} .badges {
  display: flex;
  margin: 5px -5px;
  flex-direction: row;
  flex-wrap: wrap;
  order: 5;
  flex-shrink: 1;
  gap: 5px;
  .seis{
    margin-left: -10px;
  }
  .eis{
    margin-left: -10px;
  }
  .public-offer{
    margin-left: auto;
  }
}

.text-badges {
  border-style: solid;
  border-width: thin;
  color: #4C8484;
  width: 45px;
}

.captcha{
  margin-top: 15px;
  margin: auto;
}
