.ql-editor{
    min-height: 500px !important;
    max-height: 600px;
    overflow: hidden;
    overflow-y: scroll;
    overflow-x: scroll;
  }

p {
    text-align: justify;
}